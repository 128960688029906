// 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px!important;
    }

    .page-title-right{
        @media (max-width: 575.98px) {
            display: none;
        }
    }
}