// 
// Widgets.scss
// 

.main-chart {
    .chart-border-left {
        border-left: 1.4px solid $border-color;
        padding: 2px 20px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

/* Activity */

.activity-feed {
    list-style: none;

    .feed-item {
        position: relative;
        padding-bottom: 27px;
        padding-left: 16px;
        border-left: 2px solid $light;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 9px;
            left: -7px;
            width: 12px;
            height: 12px;
            border-radius: 2px;
            border: 2px solid $primary;
            background-color: $card-bg;
        }

        &:last-child {
            border-color: transparent;
        }
    }
}

.text-slider {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .text-slider > ul {
    white-space: nowrap;
  }
  .text-slider .move-text {
        animation: moveSlideshow 6s linear infinite;
  }
  
  @keyframes moveSlideshow {
    100% { 
        transform: translateX(-52%);  
    }
  }


// Tasks box

.tasks-box {
    .custom-control{
        .edit-icon {
            opacity: 0;
            transition: all 0.2s;
        }
        &:hover {
            .edit-icon {
                opacity: 1;
            }
        }
    }
}

