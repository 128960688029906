// 
// timeline.scss
//

// 
// timeline.scss
//


.timeline{
    position: relative;
    margin-bottom: 30px;
    &:before {
        content: "";
        position: absolute;
        width: 2px;
        top: 30px;
        left: 0;
        bottom: 0;
        background-color: rgba($blue, 0.07);
    }

    .timeline-item{
        display: flex;
    }

    .timeline-block{
        width: 100%;
    }

    .time-show-btn{
        margin-bottom: 30px;
    }

    .timeline-box{
        margin: 20px 0;
        position: relative;
        .timeline-info-icon {
            margin-right: auto!important;
            margin-left: 1.2rem!important;
        }
    }
    .timeline-icon{
        display: none;
    }

}

@include media-breakpoint-up(lg) {
    .timeline{
        .timeline-block{
            width: 50%;
        }

        &:before {
            left: 50%;
        }
        .time-show-btn{
            position: relative;
            left: 67px;
            text-align: right;
        }

        .timeline-icon{
            display: block;
            position: absolute;
            left: -115px;
            padding: 6px;
            &:after{
                content: "";
                position: absolute;
                width: 70px;
                height: 12px;
                top: 12px;
                left: 23px;
                border-top: 5px solid $body-bg;
                border-bottom: 5px solid $body-bg;
            }
        }
    }


    .timeline-item{
        &::before {
            content: "";
            display: block;
            width: 50%;
        }

        .timeline-icon {
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
                background: $body-bg;
            .timeline-circle {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                border: 7px solid $body-bg;
            }
        }

        &.timeline-left{
            text-align: right;
            &::after {
                content: "";
                display: block;
                width: 50%;
            }

            &::before {
                display: none;
            }
        }

        .timeline-box{
            margin-left: 100px;
        }

        &.timeline-left{
            .timeline-box{
                margin-left: 0;
                margin-right: 100px;
                .timeline-info-icon {
                    margin-right: 1.2rem!important;
                    margin-left: auto!important;
                }
            }

            .timeline-icon{
                right: -119px;
                left: auto;
                top: 50%;
                padding: 6px;
                transform: translateY(-50%);
                border-radius: 50%;
                background: $body-bg;
                &:after{
                    right: 23px;
                    left: auto;
                }
            }
        }
    }
}


@each $color, $velue in $theme-colors {
    .timeline-item {
        &.timeline-#{$color} {
            .timeline-icon {
                .timeline-circle {
                    background: #{$velue};
                    box-shadow: 0 0 0 2px rgba($velue, 0.8);
                }
                &:after {
                    background: #{$velue};
                }
            }
        }
    }
}

@include media-breakpoint-down(md) { 
    .timeline {
        .timeline-box {
            margin: 30px 0;
        }
    }
}