//
// Forms.scss
//


// checkbox input right

.form-check-right{
    padding-left: 0;
    display: inline-block;
    padding-right: 1.25rem;
    .form-check-input{
      right: 0;
      margin-left: 0;
    }
  
    .form-check-label{
      display: block;
    }
  }
  
  
  .custom-control-right{
    padding-left: 0;
    padding-right: 1.5rem;  
    display: inline-block;
    .custom-control-label{
      display: inline-block;
      &:before, &:after{
        left: auto;
        right: -1.5rem;
      }
    }
  
    .custom-control-input{
      left: auto;
    }
}

.custom-control-label {
    cursor: pointer;
}



// custom-checkbox 
.custom-checkbox {
  .custom-control-label {
      &:before {
          height: 18px;
          width: 18px;
          top: 2px;
      }
  }
  &.custom-control-right {
    .custom-control-input:checked~.custom-control-label:after {
        left: auto;
    }
  }
  .custom-control-input:checked~.custom-control-label::after {
      background-image: none;
  }
  .custom-control-input {
      &:checked~.custom-control-label {
          &:before {
              position: absolute;
              background-color: transparent;
              border-color: $primary;
          }
          &:after {
              color: $primary;
              content: "\F012C";
              display: inline-block;
              font: normal normal normal 24px/1 "Material Design Icons";
              text-rendering: auto;
              line-height: inherit;
              font-size: 13px;
              top: 2px;
              left: -22px;
              font-weight: 600;
          }
      }
      &:focus~.custom-control-label {
          &:before {
              box-shadow: none;
          }
      }
  }
  .custom-control-label {
      transition: all 0.5s;
      &.active {
          &:before {
              border-color: $primary;
          }
      }
  }
}