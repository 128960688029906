// 
// ecommerce.scss
//

// product

.search-box{
    .form-control{
        border-radius: 30px;
        padding-left: 40px;
    }
    .search-icon{
        font-size: 16px;    
        position: absolute;
        left: 13px;
        top: 0;
        line-height: 38px;
    }
}

.product-img {
    .p-img {
        display: block;
    }
    .p-h-img {
        display: none;
    }
}

.categories-list{
    padding: 4px 0;
    li{
        a{
            display: block;
            padding: 4px 12px;
            color: $body-color;
        }

        &.active{
            a{
                color: $primary;
            }
        }
    }
}


// product box

.product-box{
    border: 1px solid $border-color;
    position: relative;
    transition: all 0.4s;
    overflow: hidden;
    margin-top: 20px;
    cursor: pointer;
    
    &:hover{
        box-shadow: $box-shadow;

        .product-color{
            opacity: 1;
            transform: translateY(-50%) translateX(0px);
            visibility: visible;
        }
        .product-img {
            .p-img {
                display: none;
            }
            .p-h-img {
                display: block;
            }
        }
    }
}

.product-img{
    position: relative;
    
    .product-ribbon{
        position: absolute;
        top: 20px;
        left: 20px;
        padding: 6px 8px;
    }
}

.product-wishlist{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
    a{
        color: $gray-500;
        transition: all 0.3s ease-in-out;
        &:hover {
            .heart-icon {
                fill: $danger;
                color: $danger;
            }
        }
    }
}

.product-content{
    position: relative;
    transition: all 0.2s;
}

.product-color{
    position: absolute;
    right: 20px;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%) translateX(30px);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    li {
        margin: 5px 0;
    }
}


// product-detail

.product-detail{

    .nav-pills{
        .nav-link{
            margin-bottom: 7px;
            &.active{
                background-color: $gray-300;
            }

            .tab-img{
                width: 5rem;
            }

        }
    }

    .product-img{
        padding: 24px;
    }
}

.product-desc-list{
    li{
        padding: 2px 0px;
    }
}

.product-desc-color{
    a{
        display: inline-block;
        text-align: center;
        color: $body-color;
        margin: 4px;
        border: 2px solid $border-color;
        border-radius: 4px;

        &.active, &:hover{
            color: $primary;
            border-color: $primary !important;
        }
    }
}


.product-review-link{
    .list-inline-item{
        a{
            color: $gray-600;
            padding: 4px 8px 4px 0;
            font-size: 15px;
        }
    }           
}

// ecommerce cart

.product-cart-touchspin{
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    border-radius: $border-radius;
    .form-control{
        border-color: transparent;
        height: 32px
    }
    
    .input-group-btn .btn{
        background-color: transparent !important;
        border-color: transparent !important;
        color: $primary !important;
        font-size: 16px;
        padding: 3px 12px;
        box-shadow: none;
    }
}

.visa-bg-icon {
    position: absolute;
    font-size: 385px;
    color: rgba($white, 0.035);
    right: 0;
    top: 56%;
    transform: translateY(-50%);
}


// ecommerce checkout

.shipping-address{
    box-shadow: none;
    &.active{
        border-color: $primary !important;
    }
}