//
// Form Wizard
//

// twitter-bs-wizard

.twitter-bs-wizard{

  .twitter-bs-wizard-nav{
    padding: 4px;
    background-color: rgba($primary, 0.1);

    .step-number{
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 34px;
      border: 2px solid $primary;
      color: $primary;
      text-align: center;
      border-radius: 50%;

      @media (max-width: 991.98px) {
        display: block;
        margin: 0 auto 8px !important;
      }
    }

    .nav-link{
      @media (min-width: 992px) {
        text-align: left;
      }
      &.active{
        background-color: transparent;
        color: $gray-700;

        .step-number{
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
  
  .twitter-bs-wizard-pager-link{
    padding-top: 24px;
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      li{
        display: inline-block;
        a{
          display: inline-block;
          padding: .47rem .75rem;
          background-color: $primary;
          color: $white;
          border-radius: .25rem;
        }
        &.disabled{
          a{
            cursor: not-allowed;
            background-color: lighten($primary, 8%);
          }
        }

        &.next{
          float: right;
        }
      }
    }

    
    // custom-wizard
    .custom-wizard {
      position: relative;
      &:before {
        content: "";
        background: rgba($primary, 0.2);
        height: 2px;
        width: 100%;
        position: absolute;
        top: 25px;
      }
      .step-number{
        display: inline-block;
        width: 38px;
        height: 38px;
        line-height: 34px;
        border: 2px solid $primary;
        color: $primary;
        text-align: center;
        border-radius: 50%;
        position: relative;
        background: $white;
        z-index: 1;
        transition: all 0.5s;
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          border: 1px solid rgba($primary, 0.3);
          border-radius: 50%;
          height: 38px;
          width: 38px;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          margin: 0 auto;
          text-align: center;
          opacity: 0;
        }
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          border: 1px solid rgba($primary, 0.1);
          border-radius: 50%;
          height: 38px;
          width: 38px;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          margin: 0 auto;
          text-align: center;
          opacity: 0;
        }
  
        @media (max-width: 991.98px) {
          display: block;
          margin: 0 auto 8px !important;
        }
      }
  
      .nav-link{
        @media (min-width: 992px) {
          text-align: left;
        }
        &.active{
          background-color: transparent;
          color: $gray-700;
  
          .step-number{
            background-color: $primary;
            color: $white;
            &:after {
              height: 54px;
              width: 54px;
              opacity: 1;
            }
            &:before {
              height: 70px;
              width: 70px;
              opacity: 1;
            }
          }
        }
      }
    }
}

.twitter-bs-wizard-tab-content{
  padding-top: 24px;
  min-height: 262px;
}

// verti-nav-wizard
.verti-nav-wizard{
  .twitter-bs-wizard-nav{
    .nav-item{
      .nav-link{
        margin: 4px 0;
        @media (max-width: 991.98px) {
          text-align: center;
        }
      }
    }
  }
}